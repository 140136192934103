import React from 'react'
import { useMediaQuery } from '@mui/material'
import * as classes from '../../styles/HowItWorkSection.module.scss'
import InfoCard from './InfoCard'
import imgsrc from '../../images/whywe.svg'
import section1img from "../../images/hiw_section1.svg";

export default function HowItWorkSection() {

  return (
   <>
    <InfoCard 
        title="Empowering Experts: Our Unique Edge"
        infoText={
          <>
            <strong>Expert-Driven Platform:</strong> Leveraging our deep industry experience and cutting-edge technology, we've crafted a platform where SMEs can amplify their skills, connections, and expertise to act in a capacity akin to recruiters.<br />
            <strong>Monetise Your Expertise:</strong> Dive into an additional stream of income by channelling your vast experience and connections, all while benefiting from the perks without the pitfalls of traditional recruitment.<br />
            <strong>Cutting-Edge Technology:</strong> Experience seamless recruitment processes on our state-of-the-art cloud platform.<br />
            <strong>Efficiency & Security:</strong> Our platform ensures that employers connect faster with top candidates, confident in the knowledge they have been meticulously vetted and verified.
          </>                
      }
        img={section1img} 
      />

      <InfoCard 
        title="How it works" 
        description="Dive into our streamlined process where experts, like you, can transform professional connections into successful recommendations. Here's a glimpse of how it unfolds:" 
        img={imgsrc} 
        infoTitle="Expert Recruiters"
        infoText={
          <>
            At Experts Circle, we offer two game-changing paths to streamline recruitment:
            <ul className={classes.stepList}>
              <li><strong>Proactive Recommendations:</strong> Experts like Tim find job vacancies and actively recommend qualified contacts. Through our platform, these recommendations are verified and sent directly to hiring managers.</li>
              <li><strong>Employer-Initiated Postings:</strong> Employers list job openings, and our system matches them with the best Expert Recruiters. These experts then tap into their networks to suggest ideal candidates, who are subsequently verified and recommended.</li>
            </ul>
            Dive deeper into our revolutionary approach with our <a href="/how_it_works">detailed guide</a>.
          </> 
        }       
      />      
    </>
  )
}
