import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useMediaQuery } from '@mui/material';
import * as classes from '../../styles/TopRatedExperts.module.scss';
import { graphql, useStaticQuery } from "gatsby";

// This component now accepts a single talent as props and renders its details.
const TopRatedExpertCard = ({ talent }) => (
  <div className={classes.expertCard}>
    <div className={classes.profileImg}>
      <img src={talent.image.url} alt={talent.first_name.text || "Expert Profile"} />
    </div>
    <p className={classes.expert_name}>{talent.first_name.text}</p>
    <p className={classes.expert_city}>{talent.skill.text}</p>
    <p className={classes.expert_city}>{talent.location.text}</p>
    <div className={classes.horizontalLine} />
    <div className={classes.expertMetaInfo}>
      <div>
        <p className={classes.meta_title}>Years of Experience</p>
        <p className={classes.meta_data}>{talent.years_of_experience.text}</p>
      </div>
      <div>
        <p className={classes.meta_title}>Score</p>
        <p className={classes.meta_data}>{talent.score}</p>
      </div>
    </div>
  </div>
);

// This component fetches data and renders a slider with TopRatedExpertCards.
export default function TopRatedExperts() {
  const matches1000 = useMediaQuery('(min-width:1000px)');
  const matches1250 = useMediaQuery('(min-width:1250px)');
  
  const { allPrismicTopExpertTalent } = useStaticQuery(graphql`
    {
      allPrismicTopExpertTalent {
        edges {
          node {
            data {
              top_expert_talent {
                score
                first_name {
                  text
                }
                image {
                  url
                  alt
                }
                location {
                  text
                }
                skill {
                  text
                }
                years_of_experience {
                  text
                }
              }
            }
          }
        }
      }
    }
  `);

  const talents = allPrismicTopExpertTalent.edges.flatMap(edge => edge.node.data.top_expert_talent);

  if (talents.length === 0) {
    // Optional: Return a loading spinner or a message indicating no data is available
    return <div></div>;
  }

  return (
    <div className={classes.root}>
      <div className={classes.info}>
        <h1 className={classes.title}>Top rated experts</h1>
        <p className={classes.details}>
          Here are some of our top rated experts who have successfully placed candidates.
        </p>
      </div>

      <div className={classes.sliderContainer}>
        <Slider
          arrows={false}
          dots
          infinite
          speed={500}
          slidesToShow={matches1000 ? (matches1250 ? 3 : 2) : 1}
          slidesToScroll={1}
        >
          {talents.map((talent, index) => (
            <div key={index} style={{ padding: 10 }}>
              <TopRatedExpertCard talent={talent} />
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
}
