// extracted by mini-css-extract-plugin
export var root = "TopRatedExperts-module--root--neAV8";
export var title = "TopRatedExperts-module--title--bRLgT";
export var details = "TopRatedExperts-module--details--x9+Q5";
export var expertCard = "TopRatedExperts-module--expertCard--4brhV";
export var profileImg = "TopRatedExperts-module--profileImg--LuuA6";
export var expert_name = "TopRatedExperts-module--expert_name---qLLN";
export var expert_city = "TopRatedExperts-module--expert_city--sxK1r";
export var horizontalLine = "TopRatedExperts-module--horizontalLine--Z5hm0";
export var expertMetaInfo = "TopRatedExperts-module--expertMetaInfo--P2RHI";
export var meta_title = "TopRatedExperts-module--meta_title--CZU6D";
export var meta_data = "TopRatedExperts-module--meta_data--jLlKd";
export var info = "TopRatedExperts-module--info--N9mlh";
export var sliderContainer = "TopRatedExperts-module--sliderContainer--bnWPN";